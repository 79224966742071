<!-- 卷轴式幸运机招财进宝 -->
<style scoped>
    .column-move{
        transition:transform cubic-bezier(0.665, -0.065, 0.325, 1.065);
    }
    .column-move img{
        transform:translate(-50%, -50%);
    }
    .bg{
        background: url('@/assets/images/room/tiger/god_bg.jpg') no-repeat top right/cover;
    }
    .max-h-85{
        max-height: 85%;
    }
    .max-w-75{
        max-width: 75%;
    }
    .led-circle{
        margin:-2rem;
        background:
            radial-gradient(circle, rgb(255, 255, 255) .3rem, rgb(72, 229, 255) .5rem, rgb(72, 229, 255) .55rem, rgb(0, 186, 217) .6rem, rgba(0, 206, 255, 0.39) .75rem, transparent 1.75rem) 0 0 / 9.12% 5rem repeat-x,/*上*/
            radial-gradient(circle, rgb(255, 255, 255) .3rem, rgb(72, 229, 255) .5rem, rgb(72, 229, 255) .55rem, rgb(0, 186, 217) .6rem, rgba(0, 206, 255, 0.39) .75rem, transparent 1.75rem) 0 0 / 9.12% 12.5% repeat-y,/*左*/
            radial-gradient(circle, rgb(255, 255, 255) .3rem, rgb(72, 229, 255) .5rem, rgb(72, 229, 255) .55rem, rgb(0, 186, 217) .6rem, rgba(0, 206, 255, 0.39) .75rem, transparent 1.75rem) 0 100% / 9.12% 5rem repeat-x,/*下*/
            radial-gradient(circle, rgb(255, 255, 255) .3rem, rgb(72, 229, 255) .5rem, rgb(72, 229, 255) .55rem, rgb(0, 186, 217) .6rem, rgba(0, 206, 255, 0.39) .75rem, transparent 1.75rem) 100% 0 / 8.62% 12.5% repeat-y/*右*/
    }
    .control-container{
        /* background: radial-gradient(circle at top, transparent 3.4rem, rgba(255, 129, 0, .5) 3.5rem, #8b0000); */
        border-top-left-radius: 4rem 4rem;
        border-top-right-radius: 4rem 4rem;
    }
    .wander:hover,.gold:hover::after{
        animation-play-state: paused;
    }
    .gold{
        box-shadow: 0 -.1rem 0 .1rem #ffcb2e,-.01rem .03rem 0 .05rem #fffdc9 inset;
        background-color: #fff27b;
        color: #fff17a;
        text-shadow: .1rem -.1rem #efaf1c;
    }
    .gold::after{
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        left: 50%;
        top: 50%;
        background-image: linear-gradient(45deg, transparent 40%, #fff 50%, transparent 60%);
        width: 200%;
        height: 200%;
        transform: translate(-50%, -50%);
        z-index: -1;
        border-radius: 50%;
        animation: light-height 2s ease-in-out infinite alternate;
    }
    @keyframes light-height{
        from{
            top:0%;
        }
        to{
            top: 100%;
        }
    }
    .gold>div{
        background: #ffd93b;
        box-shadow: -.03rem .03rem 0 .03rem #fffee8 inset,0 0 .2rem .1rem #eeb228 inset,0 .1rem 0 .1rem #eeb228 inset;
    }
    .wander{
        animation: wander 2s ease-in-out infinite alternate;
    }
    @keyframes wander {
        to{
            margin-top:-.5rem;
            margin-bottom:.5rem;
        }
    }
    .btn-danger-3d{
        box-shadow:0 .3rem 0 0 #9d2732,0 .3rem .1rem .12rem #313131, inset 0 .1rem .1rem rgba(255, 255, 255, .7), inset 0 0 .3rem rgba(252, 252, 252, .6);
        background-image: linear-gradient(to top, rgba(0, 0, 0, .3), rgba(255, 255, 255, .1));
    }
    .ms-10p{
        margin-left:10%;
    }
    .pt-58p{
        padding-top:58%;
    }
    .scroll-container>div:nth-child(1)>div{
        transition-duration:1.5s;
    }
    .scroll-container>div:nth-child(2)>div{
        transition-duration:2s;
    }
    .scroll-container>div:nth-child(3)>div{
        transition-duration:2.5s;
    }
    .scroll-container>div:nth-child(4)>div{
        transition-duration:3s;
    }
    .scroll-container>div:nth-child(5)>div{
        transition-duration:3.5s;
    }
    .line-end::after{
        content:"";
        position:absolute;
        width: .05rem;
        height: 96%;
        top:2%;
        right: 0;
        background: linear-gradient(0deg,transparent 0%,#fbff00 50%, transparent 100%);
        border-radius: 100%;
    }
    .crease>span{
        text-shadow: 0.05rem 0.05rem 0px #ffe201;
    }
    .crease:hover{
        margin-bottom: .5rem;
        transition: margin-bottom .5s;
    }
    .rule:hover{
        animation: swing 1s infinite alternate;
        transform-origin: bottom;
    }
    @keyframes swing {
        from{
            transform: rotateZ(-10deg);
        }
        to{
            transform: rotateZ(5deg);
        }
    }
    .shadow-gold{
        text-shadow: yellow 0px -1px 1px;
    }
    .radix-item:nth-child(1){
        transform: rotate(45deg);
        margin-bottom:3rem;
        animation: radix-wander-1 .4s ease-in-out infinite alternate;
    }
    .radix-item:nth-child(2){
        transform: rotate(120deg);
        margin-bottom:-3rem;
        animation: radix-wander-2 .5s ease-in-out infinite alternate;
    }
    .radix-item:nth-child(3){
        transform: rotate(70deg);
        margin-bottom:4rem;
        animation: radix-wander-3 .8s ease-in-out infinite alternate;
    }
    .radix-item:nth-child(4){
        transform: rotate(90deg);
        margin-bottom:-3rem;
        animation: radix-wander-4 .6s ease-in-out infinite alternate;
    }
    .radix-item:hover{
        transform: rotate(0deg) scale(1.5) !important;
        transition: transform .5s;
    }
    @keyframes radix-wander-1 {
        100%{
            margin-bottom: 3.3rem;
        }
    }
    @keyframes radix-wander-2 {
        100%{
            margin-bottom: -3.3rem;
        }
    }
    @keyframes radix-wander-3 {
        100%{
            margin-bottom: 4.3rem;
        }
    }
    @keyframes radix-wander-4 {
        100%{
            margin-bottom: -3.3rem;
        }
    }
    /* .bg-poker{
        border:.3rem solid #fff;
        background-image: 
            repeating-linear-gradient(45deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) .5rem, #302e9b .5rem, #302e9b 0.7rem),
            repeating-linear-gradient(135deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) .5rem, #302e9b .5rem, #302e9b 0.7rem);
    } */
</style>
<template>
    <div class="d-flex flex-column h-100">
        <!-- 横向导航 -->
        <navbar class="order-first d-flex flex-column" :has-chat-msg="hasChatMsg" :is-fullscreen="isFullscreen" ></navbar>
        <!-- 内容 -->
        <div class="d-flex flex-column flex-grow-1 position-relative justify-content-center bg">

            <!-- <div class="position-relative">
                <div class="rounded-5 bg-info bg-poker d-flex justify-content-center align-items-center position-absolute top-0 start-0" style="width:8rem;height:5.5rem">
                    <img class="rounded-pill" src="logo.png" style="width: 80%;background: #302e9b;padding: 0.3rem;" alt="">
                </div>
                <div class="rounded-5 bg-light d-flex justify-content-center align-items-center position-absolute top-0 start-0" style="width:8rem;height:5.5rem">
                    
                </div>
            </div> -->

            <!-- 卷轴区 -->
            <div class="d-flex flex-grow-1">
                <div class="w-60 position-relative ms-10p">
                    <div class="d-flex position-absolute top-50 translate-middle-y h-0 w-100 rounded-10 bg-light pt-58p" style="box-shadow: rgb(0, 17, 255) 0 0 .5rem .05rem, rgb(0 109 255) 0 0 .3rem .4rem inset;">
                        <div class="position-absolute top-0 bottom-0 end-0 start-0 d-flex m-3 rounded-5" style="background:#04083d;box-shadow: rgb(71, 148, 251) -.1rem .1rem .4rem .6rem;">
                            <!-- <div class="d-flex flex-grow-1 rounded-5 scroll-container overflow-hidden" style="box-shadow: rgb(0, 17, 255) 0 0 .25rem .05rem inset, rgb(255, 255, 255) 0 0 .3rem .1rem;background-image:radial-gradient(circle, transparent 0%, transparent 1rem, rgba(255, 255, 255, 0.5) 1.1rem, rgba(255, 255, 255, 0.5) 1.2rem, transparent 1.3rem) 0 0 / 5.3rem 5rem;"> -->
                            <div class="d-flex flex-grow-1 rounded-5 scroll-container overflow-hidden" style="box-shadow: rgb(0, 17, 255) 0 0 .25rem .05rem inset, rgb(255, 255, 255) 0 0 .3rem .1rem;background-image:radial-gradient(closest-side, transparent 106%, rgba(255, 255, 255, 0.05) 107%), radial-gradient(closest-side, transparent 90%, rgba(255, 255, 255, 0.05) 89%);background-size:6rem 6rem;background-position:0 0, 3rem 3rem;">
                                <div class="col d-flex position-relative line-end">
                                    <div class="flex-grow-1 d-flex flex-column flex-column-reverse column-move" :style="columnMoveStyle[1]">
                                        <div v-for="(val,key) in scrollList[0]" :key="(val,key)" class="position-relative flex-shrink-0 h-33 rounded-circle">
                                            <span class="position-absolute top-0 start-0 rounded-circle w-100 h-100" :style="getStyle(scrollBoxStyle,1,key)"></span>
                                            <span class="position-absolute top-50 start-50 rounded-circle w-130 h-130" :style="getStyle(scrollStyle,1,key)"></span>
                                            <img class="position-absolute start-50 top-50 max-w-75 max-h-85" :style="getStyle(imgStyle,1,key)" :src="require('@/assets/images/room/tiger/'+val+'.png')" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="col d-flex position-relative line-end">
                                    <div class="flex-grow-1 d-flex flex-column flex-column-reverse column-move" :style="columnMoveStyle[2]">
                                        <div v-for="(val,key) in scrollList[1]" :key="(val,key)" class="position-relative flex-shrink-0 h-33 rounded-circle">
                                            <span class="position-absolute top-0 start-0 rounded-circle w-100 h-100" :style="getStyle(scrollBoxStyle,2,key)"></span>
                                            <span class="position-absolute top-50 start-50 rounded-circle w-130 h-130" :style="getStyle(scrollStyle,2,key)"></span>
                                            <img class="position-absolute start-50 top-50 max-w-75 max-h-85" :style="getStyle(imgStyle,2,key)" :src="require('@/assets/images/room/tiger/'+val+'.png')" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="col d-flex position-relative line-end">
                                    <div class="flex-grow-1 d-flex flex-column flex-column-reverse column-move" :style="columnMoveStyle[3]">
                                        <div v-for="(val,key) in scrollList[2]" :key="(val,key)" class="position-relative flex-shrink-0 h-33 rounded-circle">
                                            <span class="position-absolute top-0 start-0 rounded-circle w-100 h-100" :style="getStyle(scrollBoxStyle,3,key)"></span>
                                            <span class="position-absolute top-50 start-50 rounded-circle w-130 h-130" :style="getStyle(scrollStyle,3,key)"></span>
                                            <img class="position-absolute start-50 top-50 max-w-75 max-h-85" :style="getStyle(imgStyle,3,key)" :src="require('@/assets/images/room/tiger/'+val+'.png')" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="col d-flex position-relative line-end">
                                    <div class="flex-grow-1 d-flex flex-column flex-column-reverse column-move" :style="columnMoveStyle[4]">
                                        <div v-for="(val,key) in scrollList[3]" :key="(val,key)" class="position-relative flex-shrink-0 h-33 rounded-circle">
                                            <span class="position-absolute top-0 start-0 rounded-circle w-100 h-100" :style="getStyle(scrollBoxStyle,4,key)"></span>
                                            <span class="position-absolute top-50 start-50 rounded-circle w-130 h-130" :style="getStyle(scrollStyle,4,key)"></span>
                                            <img class="position-absolute start-50 top-50 max-w-75 max-h-85" :style="getStyle(imgStyle,4,key)" :src="require('@/assets/images/room/tiger/'+val+'.png')" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="col d-flex position-relative">
                                    <div class="flex-grow-1 d-flex flex-column flex-column-reverse column-move" :style="columnMoveStyle[5]">
                                        <div v-for="(val,key) in scrollList[4]" :key="(val,key)" class="position-relative flex-shrink-0 h-33 rounded-circle">
                                            <span class="position-absolute top-0 start-0 rounded-circle w-100 h-100" :style="getStyle(scrollBoxStyle,5,key)"></span>
                                            <span class="position-absolute top-50 start-50 rounded-circle w-130 h-130" :style="getStyle(scrollStyle,5,key)"></span>
                                            <img class="position-absolute start-50 top-50 max-w-75 max-h-85" :style="getStyle(imgStyle,5,key)" :src="require('@/assets/images/room/tiger/'+val+'.png')" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="position-absolute top-0 bottom-0 end-0 start-0 led-circle">
                            <!-- <canvas id="canvasAnim" class="w-100 h-100"></canvas> -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- 操作栏 -->
            <div class="d-flex control-container justify-content-between align-items-end">
                <div class="d-flex justify-content-end col">
                    <!-- 总押 -->
                    <!-- <div class="d-flex flex-column py-2">
                        <div class="lh-1 fs-5 mb-1 fw-bold text-center" style="color:rgb(255 129 0);text-shadow: .15rem .15rem 0 #8b0000;">总押</div>
                        <div class="d-flex flex-grow-1">
                            <div class="d-flex flex-column mx-2 rounded rounded-5 overflow-hidden">
                                <div class="flex-grow-1 d-flex flex-column justify-content-center bg-opacity-50" style="box-shadow:inset 0 .2rem .2rem #000, inset 0 -.1rem .2rem #d9d9d9">
                                    <div class="bg-transparent border-0 w-7rem text-dark fw-bold text-center fs-5 px-1">10000000</div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- 得分 -->
                    <div class="d-flex align-items-end" style="margin-right:-2rem">
                        <div style="margin-right:-9rem">
                            <img class="w-15rem" src="@/assets/images/room/tiger/lucky_thing3.png" alt="">
                        </div>
                        <div class="position-relative text-center">
                            <img class="w-7rem" src="@/assets/images/room/tiger/lucky_bag5.png" alt="">
                            <div class="position-absolute d-flex flex-column translate-middle-x start-50 bottom-5 fw-bold">
                                <span style="text-shadow:0 0 0 #fff;font-family:cursive">得分</span>
                                <span class="fs-5 lh-1" style="text-shadow:0px -1px 1px yellow">{{bounsWin}}</span>
                            </div>
                        </div>
                        <div style="margin:0 0 -.3rem -8.5rem;">
                            <img class="w-14rem" style="transform:rotateY(180deg)" src="@/assets/images/room/tiger/lucky_thing3.png" alt="">
                        </div>
                    </div>
                    <!-- 基数 -->
                    <div class="d-flex align-items-end position-relative">
                        <div style="margin-right:-2rem">
                            <img class="w-5rem" src="@/assets/images/room/tiger/lucky_thing2.png" alt="">
                        </div>
                        <div class="position-relative text-center">
                            <img class="w-6rem" src="@/assets/images/room/tiger/lucky_bag4.png" alt="">
                            <div class="position-absolute d-flex flex-column translate-middle-x start-50 bottom-5 fw-bold">
                                <span style="text-shadow:0 0 0 #fff;font-family:cursive">基数</span>
                                <span class="fs-5 lh-1" style="text-shadow:0px -1px 1px yellow">{{radix}}</span>
                            </div>
                        </div>
                        <div style="margin-left:-2rem">
                            <img class="w-5rem" src="@/assets/images/room/tiger/lucky_thing1.png" alt="">
                        </div>
                        <div class="d-flex align-items-center fw-bold" style="margin-left:-1rem">
                            <div class="position-relative radix-item" @click="radix=1">
                                <img class="w-2rem" src="@/assets/images/room/tiger/lucky_thing5.png" alt="">
                                <span class="position-absolute bottom-0 start-50 translate-middle-x small shadow-gold">1</span>
                            </div>
                            <div class="position-relative radix-item" @click="radix=10">
                                <img class="w-2rem" src="@/assets/images/room/tiger/lucky_thing5.png" alt="">
                                <span class="position-absolute bottom-0 start-50 translate-middle-x small shadow-gold">10</span>
                            </div>
                            <div class="position-relative radix-item" @click="radix=20">
                                <img class="w-2rem" src="@/assets/images/room/tiger/lucky_thing5.png" alt="">
                                <span class="position-absolute bottom-0 start-50 translate-middle-x small shadow-gold">20</span>
                            </div>
                            <div class="position-relative radix-item index-1" @click="radix=50">
                                <img class="w-2rem" src="@/assets/images/room/tiger/lucky_thing5.png" alt="">
                                <span class="position-absolute bottom-0 start-50 translate-middle-x small shadow-gold">50</span>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- 开始 -->
                <div class="position-relative">
                    <div class="rounded-circle w-5rem h-5rem d-flex align-items-center justify-content-center wander gold fw-bold fs-5 index-1 p-2 position-relative overflow-hidden mx-2" @click="getResult">
                        <div class="rounded-circle d-flex align-items-center justify-content-center w-100 h-100">开始</div>
                    </div>
                    <div class="position-absolute bottom-0 start-50 translate-middle-x">
                        <img class="w-10rem" src="@/assets/images/room/tiger/lucky_thing4.png" alt="">
                    </div>
                </div>
                <div class="d-flex col">
                    <!-- 押分 -->
                    <div class="d-flex align-items-end position-relative">
                        <div class="position-relative crease" @click="addScore(-1)">
                            <img class="w-3_5rem" src="@/assets/images/room/tiger/decrease.png" alt="">
                            <span class="position-absolute translate-middle-x start-50 bottom-10 fw-bold fs-4 lh-1">-</span>
                        </div>
                        <img class="w-6rem" src="@/assets/images/room/tiger/lucky_bag2.png" alt="">
                        <div class="position-absolute d-flex flex-column translate-middle-x start-50 bottom-10 fw-bold text-center">
                            <span style="text-shadow:0 0 0 #fff;font-family:cursive">押分</span>
                            <span class="fs-5 lh-1" :style="subScoreStyle" style="text-shadow:0px -1px 1px yellow">{{subScore}}</span>
                        </div>
                        <div class="position-relative crease" @click="addScore(1)">
                            <img class="w-4rem" src="@/assets/images/room/tiger/increase.png" alt="">
                            <span class="position-absolute translate-middle-x start-50 bottom-10 fw-bold">+</span>
                        </div>
                    </div>
                    <!-- 自动 -->
                    <div></div>
                    <!-- 规则 -->
                    <div class="d-flex align-items-end position-relative">
                        <img class="w-7rem" src="@/assets/images/room/tiger/lucky_bag3.png" alt="">
                        <div class="position-absolute d-flex flex-column translate-middle-x start-50 bottom-10 fw-bold text-warning" @click="isShowRule=true">
                            <i class="bi bi-question-lg fs-1 rule"></i>
                        </div>
                    </div>
                </div>
                
            </div>
            <!-- 规则 -->
            <div v-if="isShowRule" class="d-flex justify-content-center align-items-center position-absolute top-0 bottom-0 end-0 start-0 bg-dark bg-opacity-50 index-1051">
				<div class="d-flex flex-column rounded-3 overflow-hidden shadow">
					<div class="bg-navy d-flex justify-content-between py-2 px-3 text-light">
						<span>规则说明</span>
						<i class="bi bi-x-octagon" @click="isShowRule=false"></i>
					</div>
                    <div class="p-3 bg-blue d-flex flex-wrap text-light">
                        <div class="text-center text-light">
                            <div class="d-flex border border-danger mb-2">
                                <div class="text-vertical bg-danger px-1">【中奖线】</div>
                                <div class="flex-grow-1 d-flex flex-column p-1">
                                    <div class="text-start small">相同图案构成以下蓝点组成的线条时即为中奖：</div>
                                    <div class="d-flex">
                                        <div v-for="k in 3" :key="k" class="d-flex flex-column justify-content-center px-1">
                                            <i :class="['bi-'+k+'-circle']" class="bi text-info"></i>
                                            <div class="d-flex border">
                                                <div v-for="i in 5" :key="i" class="d-flex flex-column">
                                                    <div v-for="j in 3" :key="j" :class="[(k==j)?'bg-primary':'bg-dark bg-opacity-50']" class="border ps-2 pt-2"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column justify-content-center px-1">
                                            <i class="bi bi-4-circle text-info"></i>
                                            <div class="d-flex border">
                                                <div v-for="i in 5" :key="i" class="d-flex flex-column">
                                                    <div v-for="j in 3" :key="j" :class="[(i==j||i+j==6)?'bg-primary':'bg-dark bg-opacity-50']" class="border ps-2 pt-2"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column justify-content-center px-1">
                                            <i class="bi bi-5-circle text-info"></i>
                                            <div class="d-flex border">
                                                <div v-for="i in 5" :key="i" class="d-flex flex-column">
                                                    <div v-for="j in 3" :key="j" :class="[(Math.abs(i-j)==2||(i==2&&j==2))?'bg-primary':'bg-dark bg-opacity-50']" class="border ps-2 pt-2"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column justify-content-center px-1">
                                            <i class="bi bi-6-circle text-info"></i>
                                            <div class="d-flex border">
                                                <div v-for="i in 5" :key="i" class="d-flex flex-column">
                                                    <div v-for="j in 3" :key="j" :class="[((i==1||i==5||i==3)&&j==2||i==2&&j==3||i==4&&j==1)?'bg-primary':'bg-dark bg-opacity-50']" class="border ps-2 pt-2"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column justify-content-center px-1">
                                            <i class="bi bi-7-circle text-info"></i>
                                            <div class="d-flex border">
                                                <div v-for="i in 5" :key="i" class="d-flex flex-column">
                                                    <div v-for="j in 3" :key="j" :class="[((i==1||i==5||i==3)&&j==2||i==2&&j==1||i==4&&j==3)?'bg-primary':'bg-dark bg-opacity-50']" class="border ps-2 pt-2"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex border border-danger mb-2">
                                <div class="text-vertical bg-danger px-1">【倍率说明】</div>
                                <div class="flex-grow-1 d-flex flex-column p-1">
                                    <div class="text-start small">组成中奖线的图案倍率如下<span class="text-danger fw-bold">(不含百搭)</span>：</div>
                                    <div class="d-flex flex-column">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div>
                                                <img class="w-3_5rem px-1" src="@/assets/images/room/tiger/god_1.png" alt="">
                                                <img class="w-3_5rem px-1" src="@/assets/images/room/tiger/god_2.png" alt="">
                                                <img class="w-3_5rem px-1" src="@/assets/images/room/tiger/god_3.png" alt="">
                                                <img class="w-3_5rem px-1" src="@/assets/images/room/tiger/god_4.png" alt="">
                                            </div>
                                            <span class="pe-1 fs-4">×2</span>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center py-1">
                                            <div>
                                                <img class="w-3_5rem px-1" src="@/assets/images/room/tiger/god_5.png" alt="">
                                                <img class="w-3_5rem px-1" src="@/assets/images/room/tiger/god_6.png" alt="">
                                                <img class="w-3_5rem px-1" src="@/assets/images/room/tiger/god_7.png" alt="">
                                            </div>
                                            <span class="pe-1 fs-4">×4</span>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center py-1">
                                            <div>
                                                <img class="w-3_5rem px-1" src="@/assets/images/room/tiger/god_8.png" alt="">
                                                <img class="w-3_5rem px-1" src="@/assets/images/room/tiger/god_9.png" alt="">
                                            </div>
                                            <span class="pe-1 fs-4">×6</span>
                                        </div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex border border-danger mb-2">
                                <div class="text-vertical bg-danger px-1">【特殊图案】</div>
                                <div class="flex-grow-1 d-flex flex-column p-1">
                                    <div class="text-start"></div>
                                </div>
                            </div>
                            <div class="d-flex border border-danger">
                                <div class="text-vertical bg-danger px-1">【操作说明】</div>
                                <div class="flex-grow-1 d-flex flex-column p-1">
                                    <div class="text-start"></div>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
			</div>
        </div>
    </div>
</template>
<script>
import navbar from '@/components/public/navbar.vue';
import { useLayer } from '@/assets/js/useLayer';
import { COMMAND, IS_DEBUG,LAYER_TYPE,GAME_ID,RESULT,MUTATION_TYPE,GAME_CODE } from "@/assets/js/config.js";
import { nextTick } from 'vue';
export default {
    components: {
        navbar
    },
    props: {
        hasChatMsg:false,//是否有未读的客服消息
        isPageReady:false,//页面是否加载完毕
        isFullscreen:{
            type:Boolean,
            default:false
        },//当前是否全屏
    },
    data() {
        return {
            imgList:[
                ['god_1','god_2','god_3','god_4','god_5','god_6','god_7','god_8','god_9','god_10','god_11','god_12'],
                ['god_2','god_4','god_6','god_8','god_10','god_12','god_1','god_3','god_5','god_7','god_9','god_11'],
                ['god_3','god_6','god_9','god_12','god_2','god_5','god_8','god_11','god_1','god_4','god_7','god_10'],
                ['god_4','god_8','god_12','god_3','god_7','god_11','god_2','god_6','god_10','god_1','god_5','god_9'],
                ['god_5','god_10','god_4','god_9','god_3','god_8','god_2','god_7','god_12','god_1','god_6','god_11'],
            ],//卷轴顺序配置列表
            scrollList:[],//构建卷轴列表
            columnMoveStyle:{
                1:{},
                2:{},
                3:{},
                4:{},
                5:{}
            },//单列卷轴样式
            imgStyle:{},//单个卷轴样式
            scrollBoxStyle:{},//单个卷轴容器样式
            scrollStyle:{},//单个卷轴风格层
            isShowRule:false,//是否显示规则
            history:[],//历史记录
            shape:{
                bottom1:"bottom1",//底部一形
                middle1:"middle1",//中间一形
                top1:"top1",//顶部一形
                v:"v",//V形
                reverseV:"reverseV",//倒V形
                n:"n",//N形
                reverseN:"reverseN",//倒N形
            },//线形状
            subScore:0,//提交分
            subScoreStyle:{},//提交分样式
            radix:1,//基数设置
            bounsWin:0,//赢得积分
            gameState:0,//游戏状态（0:空闲 1:游戏中 2:结算中）
            limitred:[0,0],//限红
            isAnimation:true,//是否显示动画
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        },
        roomInfo() {
            return this.$store.state.roomInfo;
        }
    },
    mounted() {
        this.setScrollByHistory();
        //获取限红
        this.$emit('ws-send', COMMAND.slot_getlimitred,this.userInfo.id, this.userInfo.guid);
        this.setWsMessage();
    },
    methods: {
        /**
         * 设置websocket响应事件
         */
        setWsMessage(){
            this.$emit("ws-msg",async (data)=>{
                switch (data.command) {
                    case COMMAND.slot_getlimitred:
                        this.limitred=data.limitred;
                        this.subScore=data.limitred[0];
                        break;
                    case COMMAND.slot_submitbet:
                        if(data.result==0){
                            useLayer({str:"下注失败！"});
                            return;
                        }else if(data.result==-1){
                            useLayer({str:"用户不存在！"});
                            return;
                        }else if(data.result==-2){
                            useLayer({str:"积分不足！"});
                            return;
                        }else if(data.result==-3){
                            useLayer({str:"台面不存在！"});
                            return;
                        }else if(data.result==-4){
                            useLayer({str:"超出限红！"});
                            return;
                        }else{
                            // let result=[[1,2,3],[1,2,3],[1,2,3],[1,2,3],[1,2,3]];//模拟接收到结果(啥都没)
                            // let result=[[1,2,3],[7,8,9],[9,10,11],[10,11,12],[10,11,12]];//模拟接收到结果(第一行)
                            // let result=[[1,2,3],[12,1,2],[4,5,6],[6,7,8],[6,7,8]];//模拟接收到结果(第二行)
                            // let result=[[1,2,3],[6,7,8],[11,12,1],[2,3,4],[3,4,5]];//模拟接收到结果(第三行)
                            // let result=[[1,2,3],[7,8,9],[1,2,3],[3,4,5],[3,4,5]];//模拟接收到结果(V形)
                            // let result=[[1,2,3],[6,7,8],[7,8,9],[9,10,11],[10,11,12]];//模拟接收到结果(倒V形)
                            // let result=[[1,2,3],[11,12,1],[4,5,6],[7,8,9],[6,7,8]];//模拟接收到结果(N形)
                            // let result=[[1,2,3],[1,2,3],[4,5,6],[5,6,7],[6,7,8]];//模拟接收到结果(倒N形)
                            // let result=[[1,2,3],[7,8,9],[11,12,1],[3,4,5],[3,4,5]];//模拟接收到结果(W形)
                            this.$store.commit({
                                type:MUTATION_TYPE.setUserInfo,
                                userInfo:{
                                    realCoin:this.userInfo.realCoin-this.radix
                                }
                            })//扣积分
                            let result=data.result;
                            for (let i = 0; i < result.length; i++) {
                                await this.start(result[i]);
                                await this.scoreCounter(Number(data.score[i]),1);
                                this.$store.commit({
                                    type:MUTATION_TYPE.setUserInfo,
                                    userInfo:{
                                        realCoin:this.userInfo.realCoin+Number(data.score[i])
                                    }
                                })//加积分
                            }
                        }
                        break;
                }
            })
        },
        /**
         * 获取样式
         */
        getStyle(obj,scrollNum,imgIndex){
            if(obj[scrollNum-1]&&obj[scrollNum-1][imgIndex]){
                return obj[scrollNum-1][imgIndex];
            }
            return {};
        },
        /**
         * 提交并获取结果
         */
        getResult(){
            if(this.gameState==0){//游戏未结束
                this.gameState=1;//设置游戏状态为游戏中
                this.$emit('ws-send', COMMAND.slot_submitbet,this.roomInfo.game,2,"1:"+1, this.userInfo.id, this.userInfo.guid);
                // this.start([[1,2,3],[11,12,1],[4,5,6],[7,8,9],[6,7,8]]);//要去掉
            }
        },
        /**
         * 开始游戏
         */
        async start(result){
            let row;//行，存位置
            let lastHistory=this.history[0];//最后一条历史记录
            await this.setScrollByHistory();

            //设置滚动
            for (let j = 0; j < result.length; j++) {
                let baseLen=this.imgList[j].length;//基础单位长度
                let firstLen=this.history.length==0?baseLen:baseLen-(lastHistory[j][0]-1);//第一节剩余长度
                let advance=Math.floor(firstLen/3)*100+(firstLen%3)*33.333333+(baseLen/3*100);//预旋转长度=第一节剩余长度+第二节长度
                this.columnMoveStyle[j+1].transform="translateY("+(advance+33.333333*(result[j][0]-1))+"%)";
            }
            //检验成排
            for (let i = 0; i < 3; i++) {
                row=[];
                for (let j = 0; j < result.length; j++) {
                    let baseLen=this.imgList[j].length;//基础单位长度
                    let firstLen=this.history.length==0?baseLen:baseLen-(lastHistory[j][0]-1);//第一节剩余长度
                    row.push(firstLen+baseLen+(result[j][0]+i));
                }
                if(row.every((val,k,arr)=>this.scrollList[k][val-1]==this.scrollList[0][arr[0]-1])){//一排全一样，每个位置的图片都和第一个位置的图一样
                    console.log("第"+(i+1)+"行一样")
                    break;
                }
                row=null;
            }
            //检验V形
            (!row)&&(row = this.getShapeNum(result,this.shape.v));
            //检验倒V形
            (!row)&&(row = this.getShapeNum(result,this.shape.reverseV));
            //检验N形
            (!row)&&(row = this.getShapeNum(result,this.shape.n));
            //检验倒N形
            (!row)&&(row = this.getShapeNum(result,this.shape.reverseN));
            console.log(JSON.stringify(row));
            await new Promise((resolve)=>{
                setTimeout(resolve, 3500);//等待滚动完成
            })
            //添加得奖效果
            if(row){//有成排
                for (let i = 0; i < row.length; i++) {
                    this.imgStyle[i]={};
                    this.imgStyle[i][row[i]-1]={
                        animation: "img-scale .5s linear "+(.25*i)+"s alternate,img-jump .8s ease "+(.25*(row.length-1)+.5)+"s infinite alternate",
                    }
                    this.scrollBoxStyle[i]={};
                    this.scrollBoxStyle[i][row[i]-1]={
                        boxShadow:"rgb(0 206 255) 0 0 1rem, inset rgb(0 206 255) 0 0 1rem",
                        transition:"box-shadow .5s linear "+(.25*i)+"s",
                        animation:"light-circle 3s infinite"
                    }
                    this.scrollStyle[i]={};
                    this.scrollStyle[i][row[i]-1]={
                        background: "repeating-conic-gradient(rgba(0,206,255,0) 0deg, yellow 20deg, transparent 40deg,transparent 51deg)",
                        maskImage: "radial-gradient(rgba(0,0,0,1), rgba(255,255,255,0) 72%)",
                        animation:"rays-rotate 10s linear infinite,rays-scale 1s linear infinite alternate"
                    }
                }
                await new Promise((resolve)=>{
                    setTimeout(resolve, 1500);//等待效果完成
                })
                console.log(JSON.stringify(this.imgStyle))
            }

            this.history.unshift(result);
            this.gameState=0;
        },
        /**
         * 根据历史记录构建卷轴(前两轮用来滚动，第三轮用来展示，第四轮用来补空缺)
         */
        async setScrollByHistory(){
            return new Promise(async resolve=>{
                this.columnMoveStyle={
                    1:{transition:'none'},
                    2:{transition:'none'},
                    3:{transition:'none'},
                    4:{transition:'none'},
                    5:{transition:'none'}
                };
                if(this.history.length==0){//没有历史记录
                    for (let i = 0; i < this.imgList.length; i++) {
                        this.scrollList[i]=[...this.imgList[i],...this.imgList[i],...this.imgList[i],...this.imgList[i]];
                    }
                }else{//有历史记录
                    let lastHistory=this.history[0];//最后一条历史记录
                    for (let i = 0; i < this.imgList.length; i++) {
                        this.scrollList[i]=this.imgList[i].slice(lastHistory[i][0]-1).concat(this.imgList[i],this.imgList[i],this.imgList[i]);
                    }
                }
                await nextTick();
                requestAnimationFrame(()=>{//在浏览器下一次重绘前执行
                    //移除当前所有效果
                    this.columnMoveStyle={
                        1:{},
                        2:{},
                        3:{},
                        4:{},
                        5:{}
                    }
                    this.imgStyle={};
                    this.scrollBoxStyle={};
                    this.scrollStyle={};
                    resolve();
                })
            })
        },
        /**
         * 根据结果获取图形顺序号
         */
        getShapeNum(result,shape){
            let row=[];
            let direction=0;//方向
            let lastHistory=this.history[0];//最后一条历史记录
            if(shape==this.shape.n){
                direction=1;
            }else if(shape==this.shape.reverseN){
                direction=-1;
            }
            if(shape==this.shape.n||shape==this.shape.reverseN){
                for (let i = 0,j=1; i < Math.ceil(result.length/2); i++,j+=direction) {//遍历卷轴结果
                    let baseLen=this.imgList[i].length;//基础单位长度
                    let firstLen=this.history.length==0?baseLen:baseLen-(lastHistory[i][0]-1);//第一节剩余长度
                    (j==0||j==result[i].length-1)&&(direction*=-1);
                    if(i<Math.ceil(result.length/2)-1){//中间之前的项
                        if(this.imgList[i][result[i][j]-1]==this.imgList[result.length-(i+1)][ result[result.length-(i+1)][result[i].length-(j+1)]-1 ]){//如果每一项都等于它的对折项的镜面项
                            row[i]=firstLen+baseLen+(result[i][0]+j);
                            firstLen=this.history.length==0?baseLen:baseLen-(lastHistory[result.length-(i+1)][0]-1);//第一节剩余长度
                            row[result.length-(i+1)]=firstLen+baseLen+(result[result.length-(i+1)][result[result.length-(i+1)].length-(j+1)]);
                            continue;
                        }
                        row=null;
                        break;
                    }else{//中间项
                        if(this.imgList[i][result[i][j]-1]==this.imgList[0][result[0][result[0].length-(1+1)]-1]){
                            row[i]=firstLen+baseLen+(result[i][0]+j);
                            break;
                        }
                        row=null;
                    }
                }
            }else if(shape==this.shape.v){
                for (let i = 0; i < Math.ceil(result.length/2); i++) {
                    let baseLen=this.imgList[i].length;//基础单位长度
                    let firstLen=this.history.length==0?baseLen:baseLen-(lastHistory[i][0]-1);//第一节剩余长度
                    if(i<Math.ceil(result.length/2)-1){//中间之前的项
                        //如果每一项都等于它的对折项
                        if(this.imgList[i][result[i][result[i].length-(i+1)]-1]==this.imgList[result.length-(i+1)][result[result.length-(i+1)][result[result.length-(i+1)].length-(i+1)]-1]){
                            row[i]=firstLen+baseLen+(result[i][0]+(result[i].length-(i+1)));
                            firstLen=this.history.length==0?baseLen:baseLen-(lastHistory[result.length-(i+1)][0]-1);//第一节剩余长度
                            row[result.length-(i+1)]=firstLen+baseLen+(result[result.length-(i+1)][0]+(result[result.length-(i+1)].length-(i+1)));
                            continue;
                        }
                        row=null;
                        break;
                    }else{//中间项
                        if(this.imgList[i][result[i][result[i].length-(i+1)]-1]==this.imgList[0][result[0][result[0].length-(0+1)]-1]){//中间项与第一项一样
                            row[i]=firstLen+baseLen+(result[i][0]+(result[i].length-(i+1)));
                            break;
                        }
                        row=null;
                    }
                }
            }else if(shape==this.shape.reverseV){
                for (let i = 0; i < Math.ceil(result.length/2); i++) {
                    let baseLen=this.imgList[i].length;//基础单位长度
                    let firstLen=this.history.length==0?baseLen:baseLen-(lastHistory[i][0]-1);//第一节剩余长度
                    if(i<Math.ceil(result.length/2)-1){//中间之前的项
                        //如果每一项都等于它的对折项
                        if(this.imgList[i][result[i][i]-1]==this.imgList[result.length-(i+1)][result[result.length-(i+1)][i]-1]){
                            row[i]=firstLen+baseLen+(result[i][0]+i);
                            firstLen=this.history.length==0?baseLen:baseLen-(lastHistory[result.length-(i+1)][0]-1);//第一节剩余长度
                            row[result.length-(i+1)]=firstLen+baseLen+(result[result.length-(i+1)][0]+i);
                            continue;
                        }
                        row=null;
                        break;
                    }else{//中间项
                        if(this.imgList[i][result[i][i]-1]==this.imgList[0][result[0][0]-1]){//中间项与第一项一样
                            row[i]=firstLen+baseLen+(result[i][0]+i);
                            break;
                        }
                        row=null;
                    }
                }
            }
            return row;
        },
        /**
         * 追加积分
         */
        addScore(direction){
            if(this.gameState==0){
                this.subScoreStyle={};
                this.$nextTick(()=>{

                    let num=0;//实际增减量
                    if(Number(this.userInfo.realCoin)<this.radix){
                        useLayer({str:"积分不足！"});
                        return;
                    }
                    if(direction==1&&this.subScore>=this.limitred[1]){
                        useLayer({str:"当前已是单次最高押分！"});
                        return;
                    }
                    if(direction==-1&&this.subScore<=this.limitred[0]){
                        useLayer({str:"当前已是单次最低押分！"});
                        return;
                    }
                    num=this.radix;
                    if(direction==-1&&this.subScore-num<this.limitred[0]){//按基数减少后会小于限红最小
                        num=this.subScore-this.limitred[0];
                    }else if(direction==1&&this.subScore+num>this.limitred[1]){//按基数增加后会大于限红最大
                        num=this.limitred[1]-this.subScore;
                    }
                    this.subScore+=num*direction;
                    this.subScoreStyle={
                        transition:"transform .1s ease-out"
                    }
                    if(direction==1){
                        this.subScoreStyle.transform="scale(1.5)";
                    }else if(direction==-1){
                        this.subScoreStyle.transform="scale(.5)";
                    }
                    setTimeout(() => {
                        this.subScoreStyle={};
                    }, 100);
                })
            }else{
                useLayer({str:"请等待本轮游戏结束后再选择！"});
            }
        },
         /**
         * 积分计数器
         * @param {Number} score 分数
         * @param {Number} direction 方向（1或-1）
         */
        scoreCounter(score,direction){
            let step=Number(1+ (score>=999?"0".repeat(String(score).length-3):""));//正常情况下每次递增/减的值
            let count=Math.ceil(score/step);//递增/减次数
            let lastRound=this.bounsWin;//累计到上一次的得分
            let lastBounsWin=this.bounsWin;//赢得
            return new Promise(async (allResolve)=>{
                for (let j = 1; j <= count; j++) {
                    j=this.isAnimation?j:count;//不显示动画时直接将次数置为最后一次
                    await new Promise((resolve)=>{
                        setTimeout(() => {
                            this.bounsWin=(direction==1?lastRound+(j*step>score?score:(j*step)):((count-j)*step>score?score:((count-j)*step)));//当前积分=次数*步长
                            console.log(this.bounsWin)
                            // if(direction==-1){//递减时需要同时递增赢得积分
                            //     let beforeBounsWin=this.bounsWin;
                            //     this.bounsWin=lastBounsWin+(score-this.currRound);
                            //     this.$store.commit({
                            //         type:MUTATION_TYPE.setUserInfo,
                            //         userInfo:{
                            //             realCoin:this.userInfo.realCoin+(this.bounsWin-beforeBounsWin)
                            //         }
                            //     })
                            // }
                            this.$nextTick(()=>{
                                resolve();
                            })
                        },10);
                    })
                }
                allResolve();
            }).then(()=>{
                // this.$refs.sceneAudioPlayer.$refs.audio.volume=1;
            })
        },
    },
};
</script>